import styles from './Videos.module.css';
import { Grid, Button, Dialog, DialogContent, Alert, CircularProgress  } from '@mui/material';
import videoGratuite from '../../../../../Assets/images/Vidéo-gratuite.png';
import GlobalContext from "../../../../../Context/GlobalContext";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function Videos({ userData }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [showAbonnementMessage, setShowAbonnementMessage] = useState(false);
  const [showPackMessage, setShowPackMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [unlockedVideos, setUnlockedVideos] = useState(
    JSON.parse(localStorage.getItem('unlockedVideos')) || []
  );
  const context = React.useContext(GlobalContext);
  const [buttonTextMap, setButtonTextMap] = useState({});
  const handleCloseLogin = () => setShowLoginMessage(false);
  const handleCloseAbonnement = () => setShowAbonnementMessage(false);
  const handleClosePack = () => setShowPackMessage(false);
  const handleCloseVideoModal = () => setShowVideoModal(false);


  const updateUnlockedVideos = (video_id) => {
    const updatedUnlockedVideos = [...unlockedVideos, video_id];
    setUnlockedVideos(updatedUnlockedVideos);
    localStorage.setItem('unlockedVideos', JSON.stringify(updatedUnlockedVideos));
  };

  const isVideoUnlocked = (video_id) => {
    return unlockedVideos.includes(video_id);
  };
  
  useEffect(() => {
    // Fetch client data when the component mounts
    const checkUnlockedVideoExist = async () => {
      try {
        const token = localStorage.getItem("access_token_story");
  
        if (!token) {
          setIsLogged(false);
          console.log("Not logged in:", isLogged);
          return;
        }
  
        setIsLogged(true);
        console.log("Logged in:", isLogged);
  
        const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data.status === "success") {
          setClientData(response.data.result);
          const client = response.data.result;
  
          const unlockedVideosResponse = await axios.get(
            `${process.env.REACT_APP_APIURL}/client/${client.id_client}/unlocked-videos`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
  
          if (unlockedVideosResponse.data.status === "success") {
            // Extract the video IDs from the API response
            const unlockedVideoIds = unlockedVideosResponse.data.unlocked_videos.map(
              (video) => video.video_id
            );
  
            // Check if any of the video_ids from userData.library_videos exist in unlockedVideoIds
            const unlockedVideosFromLibrary = userData.library_videos.filter((video) =>
              unlockedVideoIds.includes(video.id_video_lbrv)
            );
  
            // Update the state with the matched videos
            setUnlockedVideos(unlockedVideosFromLibrary);
  
            // Check and set button text for each video
            unlockedVideosFromLibrary.forEach((video) => {
              const video_id = video.id_video_lbrv;
  
              if (unlockedVideoIds.includes(video_id)) {
                // If the video is unlocked, set the button text to "Visionner la vidéo"
                setButtonTextMap((prevState) => ({
                  ...prevState,
                  [video_id]: "Visionner la vidéo",
                }));
              } else {
                // If the video is not unlocked, set the button text to "Déverrouiller la vidéo payante"
                setButtonTextMap((prevState) => ({
                  ...prevState,
                  [video_id]: "Déverrouiller la vidéo payante",
                }));
              }
            });
          } else {
            console.error("Failed to fetch unlocked videos.");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching client data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    checkUnlockedVideoExist();
  }, [userData]);

 const handleVideoClick = async (videoSrc, client_id, video_id) => {
  const token = localStorage.getItem("access_token_story");

  // Check if user is logged in
  if (!token) {
    setShowLoginMessage(true);
    setLoading(false);
    return;
  }

  try {
    // Step 1: Check if the video is already unlocked
    const unlockedVideosResponse = await axios.get(`${process.env.REACT_APP_APIURL}/client/${client_id}/unlocked-videos`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (unlockedVideosResponse.data.status === "success") {
      const unlockedVideos = unlockedVideosResponse.data.unlocked_videos.map(video => video.video_id);

      if (unlockedVideos.includes(video_id)) {
        showVideoUnlocked(video_id);
        setButtonTextMap(prevState => ({
          ...prevState,
          [video_id]: "Visionner la vidéo"
        }));
        setLoading(false);
        return;
      }
    }

    // Step 2: Fetch video and client data
    const clientResponse = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    const videoResponse = await axios.get(`${process.env.REACT_APP_APIURL}/streaming/public/videos/g/get/get_id/${video_id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (clientResponse.data.status === "success") {
      const client = clientResponse.data.result;

      setClientData(client); // Update client data

      if (client.etat_abn !== 1) {
        setShowAbonnementMessage(true);
        setLoading(false);
        return;
      } else if (client.nbr_video === 0 || videoResponse.data.result.unlocks_tokens > client.nbr_video) {
        setShowPackMessage(true);
        setLoading(false);
        return;
      }
    }

    // Step 3: Unlock the video
    const unlockResponse = await axios.post(`${process.env.REACT_APP_APIURL}/unlock-video`, null, {
      params: { client_id, video_id }
    });

    if (unlockResponse.data.status === "success") {
      console.log("Video unlocked successfully");

      // Fetch updated client data after unlocking
      const updatedClientResponse = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (updatedClientResponse.data.status === "success") {
        setClientData(updatedClientResponse.data.result); // Update clientData with fresh info
      }

      setButtonTextMap(prevState => ({
        ...prevState,
        [video_id]: "Visionner la vidéo"
      }));

      showVideo(video_id);
    } else {
      console.error("Failed to unlock the video:", unlockResponse.data.message);
    }
  } catch (error) {
    console.error("An error occurred:", error);
  } finally {
    setLoading(false);
  }
};

  
  

  const handleVideoFree = (videoSrc) => {
    setSelectedVideo(videoSrc);
  };

  // Method to just show the video without unlocking (no API call)
const showVideo = (video_id) => {
  
    const video = userData.library_videos.find(v => v.id_video_lbrv === video_id);
    if (video) {
      setSelectedVideo(video.file_name_lbrv);
      setIsUnlocked(true);
      setShowVideoModal(false);  // Show the modal with the video
    } else {
      console.error('Video not found for id:', video_id);
    }
};

const showVideoUnlocked = (video_id) => {
  
  const video = userData.library_videos.find(v => v.id_video_lbrv === video_id);
  
  if (video) {
    setSelectedVideo(video.file_name_lbrv);
    setIsUnlocked(true);
  } else {
    console.error('Video not found for id:', video_id);
  }
};
  

  useEffect(() => {
    console.log("selectedVideo updated to:", selectedVideo);
  }, [selectedVideo]);

  useEffect(() => {
    console.log("userData.library_videos:", userData.library_videos);
  }, [userData]);

 

  const handleVideoViews = async (client_id, video_id) => {
    setLoading(true);

   
  };

  // Method to unlock the video and update localStorage
  const handleUnlockVideoClick = async (client_id, video_id) => {
    setLoading(true);
  
    if (isVideoUnlocked(video_id)) {
      setSelectedVideoId(video_id);
      setShowVideoModal(true);
      setLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem("access_token_story");
      if (!token) {
        setShowLoginMessage(true);
        setLoading(false);
        return;
      }
  
      const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.data.status === "success") {
        setClientData(response.data.result);
        const client = response.data.result;
  
        // Check subscription and token count
        if (client.etat_abn !== 1) {
          setShowAbonnementMessage(true);
          setLoading(false);
          return;
        } else if (client.nbr_video === 0 ) {
          setShowPackMessage(true);
          setLoading(false);
          return;
        }
  
        // Unlock the video
        const unlockResponse = await axios.post(`${process.env.REACT_APP_APIURL}/unlock-video`, null, {
          params: { client_id, video_id },
        });
  
        if (unlockResponse.data.status === "success") {
          updateUnlockedVideos(video_id);
          setSelectedVideoId(video_id);
          setShowVideoModal(true);
        } else {
          console.error("Failed to unlock video:", unlockResponse.data.message);
        }
      }
    } catch (error) {
      console.error("Error unlocking video:", error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUnlockAndShowModal = async (videoId, clientId) => {
    const token = localStorage.getItem("access_token_story");
  
    try {
      // Fetch updated client data to ensure nbr_video is up-to-date
      const clientResponse = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      if (clientResponse.data.status === "success") {
        setClientData(clientResponse.data.result); // Update client data in state
      } else {
        console.error("Failed to fetch updated client data.");
      }
  
      // Show the modal and set the selected video
      setShowVideoModal(true);
      setSelectedVideoId(videoId);
  
    } catch (error) {
      console.error("An error occurred while fetching client data:", error);
    }
  };
  

useEffect(() => {
  // Fetch client data when the component mounts
  const fetchClientData = async () => {
    try {
      const token = localStorage.getItem("access_token_story");
      
      if (!token) {
        setIsLogged(false);
        console.log("Not logged in:", isLogged);
        return;
      }

      setIsLogged(true);
      console.log("Logged in:", isLogged);

      const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status === "success") {
        setClientData(response.data.result);
      }

      // Check local storage for unlocked videos
      const unlockedVideos = JSON.parse(localStorage.getItem('unlockedVideos')) || [];
      
      if (userData.library_videos) {
        userData.library_videos.forEach((video) => {
          if (unlockedVideos.includes(video.id_video_lbrv)) {
            // If the video is found in localStorage, mark it as unlocked
            setIsUnlocked(true);
          }
        });
      }
      
    } catch (error) {
      console.error("An error occurred while fetching client data:", error);
    } finally {
      setLoading(false);
    }
  };

  fetchClientData();
}, [userData]);

// Optional: Log changes in isLogged to verify state updates
useEffect(() => {
  console.log("isLogged has changed:", isLogged);
}, [isLogged]);



  // State to control the number of videos displayed
  const [videosToShow, setVideosToShow] = useState(3);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading
  const [isHovered, setIsHovered] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Determine the font size based on window width
  const fontSize = windowWidth >= 900 && windowWidth <= 1526 ? '11px' : '15px';
  // Function to load more videos
  const handleLoadMore = () => {
    setIsLoading(true); // Show spinner
    setTimeout(() => {
      setVideosToShow((prev) => prev + 3); // Show 4 more videos
      setIsLoading(false); // Hide spinner after loading
    }, 1000); // Delay of 1 second for the spinner
  };

  const buttonStyles = {
    border: "2px solid #f3778f",
    backgroundColor: isHovered ? "transparent" : "#f3778f",
    color: isHovered ? "#f3778f" : "#ffffff",
    padding: "10px 28px",
    fontSize: "16px",
    fontWeight: "500",
    cursor: "pointer",
    borderRadius: "10px",
    transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out"
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData || !userData.library_videos || userData.library_videos.length === 0) {
    return (
      <div className={styles["no-videos-message-container"]}>
        <Alert className={styles["no-videos-message"]} severity="info">
          Pas de vidéo disponible pour le moment
        </Alert>
      </div>
    );
  }

  return (
    <div style={{ padding: '50px 0' }}>
       <Grid container spacing={2} justifyContent="center">
        {userData.library_videos.slice(0, videosToShow).map((video) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={video.id_video_lbrv}>
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              {/* Video Thumbnail */}
              <img
                src={video.thumbnail}
                alt="Video Thumbnail"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                  transition: "transform 0.3s ease",
                }}
                onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
              />

              {/* Hover Overlay */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  opacity: 0,
                  transition: "opacity 0.3s ease",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
                onMouseOut={(e) => (e.currentTarget.style.opacity = 0)}
              >
                <h3
                  style={{
                    margin: 0,
                    padding: "5px 10px",
                    fontSize: "1.2rem",
                    color: "#f3778f",
                  }}
                >
                  {video.name_video_lbrv}
                </h3>
                <h4
                  style={{
                    margin: 0,
                    padding: "5px 10px",
                    fontSize: "1.0rem",
                    color: "#f3778f",
                  }}
                >
                  {video.unlocks_tokens} jetons
                </h4>
                <p
                  style={{
                    margin: 0,
                    padding: "5px 10px",
                    fontSize: "0.9rem",
                    color: "#fff",
                  }}
                >
                  {video.description_video_lbrv}
                </p>
              </div>

              {/* Button Overlay */}
              <div
                style={{
                  position: "absolute",
                  bottom: "10px",
                  padding: "10px 20px",
                  borderRadius: "8px",
                  textAlign:"center",
                  margin: "0",
                  width: "100%"
                }}
              >
                {video.is_paid_video_lbrv === 1 ? (
                  isUnlocked ? (
                    <Button
                      variant="outlined"
                      sx={{
                        color: "white",
                        borderColor: "#f3778f",
                        backgroundColor: "#f3778fd9",
                        borderRadius: "10px",
                        fontSize: fontSize,
                        "&:hover": {
                          backgroundColor: "#fff", // Hover background color
                          borderColor: "#f3778f",    // Hover border color
                          color: "#f3778f",          // Hover text color
                        },
                      }}
                      onClick={() => {
                        const buttonText = buttonTextMap[video.id_video_lbrv] || "Déverrouiller la vidéo payante";
                        if (buttonText === "Visionner la vidéo") {
                          // If video is unlocked, show the video directly
                          showVideo(video.id_video_lbrv);
                        } else {
                          // If video is not unlocked, show the modal
                          handleUnlockAndShowModal(video.id_video_lbrv, clientData.id_client);
                        }
                      }}
                    >
                      {buttonTextMap[video.id_video_lbrv] || "Déverrouiller la vidéo payante"}
                    </Button>

                  ) : (
                    <Button
                    sx={{
                      color: "white",
                      borderColor: "#f3778f",
                      backgroundColor: "#f3778fd9",
                      borderRadius: "10px",
                      fontSize: fontSize,
                      "&:hover": {
                        backgroundColor: "#fff", // Hover background color
                        borderColor: "#f3778f",    // Hover border color
                        color: "#f3778f",             // Hover text color
                      },
                    }}
                      variant="outlined"
                      onClick={() => {
                        if (!isLogged) {
                          setShowLoginMessage(true);
                        } else {
                          handleUnlockVideoClick(
                            clientData.id_client,
                            video.id_video_lbrv
                          );
                        }
                      }}
                    >
                      Déverrouiller la vidéo payante
                    </Button>
                  )
                ) : (
                  <Button
                  sx={{
                    color: "white",
                    borderColor: "#f3778f",
                    backgroundColor: "#f3778fd9",
                    borderRadius: "10px",
                    fontSize: fontSize,
                    "&:hover": {
                      backgroundColor: "#fff", // Hover background color
                      borderColor: "#f3778f",    // Hover border color
                      color: "#f3778f",             // Hover text color
                    },
                  }}
                    variant="outlined"
                    onClick={() => handleVideoFree(video.file_name_lbrv)}
                  >
                    Visionner la vidéo gratuite
                  </Button>
                )}
              </div>
            </div>

            <Modal
            show={showVideoModal}
            onHide={handleCloseVideoModal}
            backdropClassName="custom-backdrop"
          >
            <Modal.Header closeButton>
              <Modal.Title>Nombre de jetons restantes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Vous avez {clientData ? clientData.nbr_video : 0} jetons restantes pour
                regarder cette vidéo
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseVideoModal}>
                Fermer
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleVideoClick(
                    video.file_name_lbrv, 
                    clientData.id_client, 
                    selectedVideoId
                  );
                }}
              >
                Montrer la vidéo
              </Button>
            </Modal.Footer>
          </Modal>
          </Grid>
        ))}
      </Grid>

      {/* Load More Button */}
      {videosToShow < userData.library_videos.length && (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Button
      variant="contained"
      style={buttonStyles}
      onClick={handleLoadMore}
      disabled={isLoading} // Disable the button during loading
      onMouseEnter={() => setIsHovered(true)}  // Trigger hover effect
      onMouseLeave={() => setIsHovered(false)} // Remove hover effect
    >
      {isLoading ? (
        <CircularProgress size={24} style={{ color: '#ffffff' }} />
      ) : (
        'Voir plus +'
      )}
    </Button>
        </div>
      )}

      <Dialog open={Boolean(selectedVideo)} onClose={() => setSelectedVideo(null)} maxWidth="lg" fullWidth>
        <DialogContent>
          <video controls autoPlay style={{ width: '100%', height: 'auto' }}>
            <source src={selectedVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>

      <Modal show={showLoginMessage} onHide={handleCloseLogin}>
        <Modal.Header closeButton>
          <Modal.Title>Message de connexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Veuillez vous connecter pour voir cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLogin}>Fermer</Button>
          <Link to="/login">
            <Button variant="primary">Connexion</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Modal show={showAbonnementMessage} onHide={handleCloseAbonnement}>
        <Modal.Header closeButton>
          <Modal.Title>Abonnement requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Vous devez avoir un abonnement pour regarder cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAbonnement}>Fermer</Button>
          <Link to="/subscriptions">
            <Button variant="primary">Acheter un abonnement</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Modal show={showPackMessage} onHide={handleClosePack}>
        <Modal.Header closeButton>
          <Modal.Title>Pack requis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>Il vous reste {clientData ? clientData.nbr_video : 0} jeton(s) ! </b></p>
          <p>Vous devez acheter un nouveau pack pour regarder cette vidéo.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePack}>Fermer</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Videos;